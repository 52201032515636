import { CSSObject } from "@emotion/react";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const headerCointainer: CSSObject = {
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    display: "flex",
    alignItems: "center",
  },
};

const heading: CSSObject = {
  flex: 1,

  [Device.Mobile]: {
    marginBottom: grid(2),
  },
};

const visitExchangeButton: CSSObject = {
  fontWeight: Fonts.Weight.Bold,

  [Device.Desktop]: {
    marginLeft: grid(2),
  },

  [Device.Mobile]: {
    width: "100%",
  },
};

const styles = {
  headerCointainer,
  heading,
  visitExchangeButton,
};

export default styles;
