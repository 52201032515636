import * as React from "react";
import { Border } from "../design-tokens/borders";
import { Colors } from "../design-tokens/colors";
import { Device } from "../design-tokens/media-queries";
import TBody from "./table-body";
import THead from "./table-head";
import TRow from "./table-row";

interface TableProps {
  minWidth?: number;
  className?: string;
}

const Table: React.FC<TableProps> = ({ minWidth, className, children }) => {
  return (
    <div
      css={{
        width: "100%",

        [Device.Mobile]: { overflowX: "auto" },
      }}
      className={className}
    >
      <table
        css={{
          position: "relative",
          width: "100%",
          tableLayout: "auto",
          minWidth,
          backgroundColor: Colors.White,

          [Device.DesktopTablet]: {
            borderTopLeftRadius: Border.Radius,
            borderTopRightRadius: Border.Radius,
          },
        }}
      >
        {children}
      </table>
    </div>
  );
};

export { Table, THead, TBody, TRow };
