import * as React from "react";
import { SiteContext } from "../../../../site-context/site-context";
import SectionCard from "../../../ui-library/cards/section-card/section-card";
import { grid } from "../../../ui-library/design-tokens/grid";
import Image from "../../../ui-library/image/image";
import Separator from "../../../ui-library/separator/separator";
import Heading from "../../../ui-library/text/heading";
import { ExchangeDetailsMapped } from "../../../../../types/api-results/exchange-details";
import { formatCurrency } from "../../../../utils/format-number";
import ExchangeCoinTickers from "../exchange-coin-tickers/exchange-coin-tickers";
import Badge from "../../../ui-library/badge/badge";
import { Colors } from "../../../ui-library/design-tokens/colors";
import styles from "./exchange-overview.styles";

interface ExchangeOverviewProps {
  data?: ExchangeDetailsMapped;
  loading?: boolean;
}

const ExchangeOverview: React.FC<ExchangeOverviewProps> = ({
  data,
  loading,
}) => {
  const {
    currency: [currency],
    btcExchangeRate,
    isMobile,
  } = React.useContext(SiteContext);

  if (!data) {
    return null;
  }

  const {
    name,
    image,
    centralized,
    isDex,
    tradeVolume24hBtc,
    trustScore,
  } = data;

  const tradingVolume = tradeVolume24hBtc
    ? formatCurrency({
        value: tradeVolume24hBtc * (btcExchangeRate || 1),
        currency,
      })
    : "-";

  return (
    <SectionCard css={styles.root}>
      <div css={styles.header}>
        <div css={styles.headerLeft}>
          <div css={styles.imageContainer}>
            <Image src={image} css={styles.image} alt={name || "logo"} />
          </div>
          <div>
            <Heading component="h1" size="large">
              {name}
            </Heading>
            {centralized && <Badge color={Colors.Orange}>CEX</Badge>}
            {isDex && <Badge>DEX</Badge>}
          </div>
        </div>

        {isMobile && <Separator margin={{ bottom: grid(2) }} />}

        <div css={styles.exchangeDetails}>
          <div css={styles.tradingVolume}>
            <div css={styles.label}>24h Trading Volume</div>
            <div css={styles.value}>{tradingVolume}</div>
          </div>
          <div>
            <div css={styles.label}>Trust Score</div>
            <div css={styles.value}>{trustScore || "-"}</div>
          </div>
        </div>
      </div>

      <Separator margin={{ bottom: grid(3) }} />

      <ExchangeCoinTickers data={data} loading={loading} />
    </SectionCard>
  );
};

export default ExchangeOverview;
