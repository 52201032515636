import * as React from "react";
import { Border } from "../design-tokens/borders";
import { Colors } from "../design-tokens/colors";
import { Fonts } from "../design-tokens/fonts";
import { grid } from "../design-tokens/grid";
import { Device } from "../design-tokens/media-queries";

const THead: React.FC = ({ children }) => {
  return (
    <thead
      css={{
        "> tr > th": {
          position: "sticky",
          top: 0,
          height: grid(5),
          fontSize: 12,
          fontWeight: Fonts.Weight.SemiBold,
          verticalAlign: "middle",
          color: Colors.MidGrey,
          backgroundColor: Colors.White,
          paddingLeft: grid(1),
          paddingRight: grid(1),
          boxShadow: `0 1px 0 ${Colors.LightGrey}`,
          whiteSpace: "nowrap",
          overflow: "hidden",
          zIndex: 1,

          ":first-of-type": {
            paddingLeft: grid(2),
          },
          ":last-of-type": {
            paddingRight: grid(2),
          },

          [Device.DesktopTablet]: {
            fontSize: 14,
            ":first-of-type": {
              borderTopLeftRadius: Border.Radius,
            },
            ":last-of-type": {
              borderTopRightRadius: Border.Radius,
            },
          },
        },
      }}
    >
      <tr>{children}</tr>
    </thead>
  );
};

export default THead;
