import { transparentize } from "polished";
import * as React from "react";
import { Colors } from "../design-tokens/colors";
import { grid } from "../design-tokens/grid";
import { Device } from "../design-tokens/media-queries";

const TBody: React.FC = ({ children }) => {
  return (
    <tbody
      css={{
        "> tr": {
          borderBottom: `1px solid ${Colors.LightGrey}`,
          [Device.DesktopTablet]: {
            ":hover": {
              backgroundColor: transparentize(0.4, Colors.LightestGrey),
            },
          },
          "> td": {
            height: grid(9),
            verticalAlign: "middle",
            padding: `${grid(2)} ${grid(1)}`,
            overflow: "hidden",
            ":first-of-type": {
              paddingLeft: grid(2),
            },
            ":last-of-type": {
              paddingRight: grid(2),
            },
          },
        },
      }}
    >
      {children}
    </tbody>
  );
};

export default TBody;
