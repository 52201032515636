import { CSSObject } from "@emotion/react";
import { Colors } from "../../../ui-library/design-tokens/colors";
import { Fonts } from "../../../ui-library/design-tokens/fonts";
import { grid } from "../../../ui-library/design-tokens/grid";
import { Device } from "../../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  paddingTop: grid(3),

  [Device.DesktopTablet]: {
    paddingTop: grid(4),
  },
};

const header: CSSObject = {
  marginBottom: grid(2),

  [Device.DesktopTablet]: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: grid(3),
  },
};

const headerLeft: CSSObject = {
  display: "flex",

  [Device.Mobile]: {
    marginBottom: grid(2),
  },
};

const imageContainer: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(3),
  height: grid(3),
  marginRight: grid(1),

  [Device.DesktopTablet]: {
    width: grid(5),
    height: grid(5),
    marginRight: grid(2),
  },
};

const image: CSSObject = {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
};

const exchangeDetails: CSSObject = {
  display: "flex",

  [Device.Mobile]: {
    marginBottom: grid(2),
  },
};

const tradingVolume: CSSObject = {
  marginRight: grid(4),

  [Device.DesktopTablet]: {
    minWidth: grid(18),
  },

  [Device.Mobile]: {
    width: "50%",
  },
};

const label: CSSObject = {
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  marginBottom: grid(0.5),
  [Device.DesktopTablet]: {
    fontSize: 14,
    marginBottom: grid(1),
  },
};

const value: CSSObject = {
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  [Device.DesktopTablet]: {
    fontSize: 18,
  },
};

const styles = {
  root,
  imageContainer,
  image,
  header,
  headerLeft,
  exchangeDetails,
  tradingVolume,
  label,
  value,
};

export default styles;
