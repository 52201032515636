import { toNumber } from "../format-number";
import {
  ExchangeDetailsMapped,
  ExchangeDetailsResult,
} from "../../../types/api-results/exchange-details";
import { DEFI_EXCHANGES } from "../../../data/defi-exchanges";
import { HASHED_EXCHANGE_URLS } from "../../../data/exchange-urls";
import { mapCoinTickers } from "./map-coin-tickers";

export const mapExchangeDetails = (
  input: ExchangeDetailsResult | undefined
): ExchangeDetailsMapped | undefined => {
  if (!input) {
    return undefined;
  }

  const mappedItems: ExchangeDetailsMapped = {
    name: input.name || undefined,
    yearEstablished: toNumber(input.year_established),
    country: input.country || undefined,
    description: input.description || undefined,
    url: input.url || undefined,
    affiliateUrl: input.name ? HASHED_EXCHANGE_URLS[input.name] : undefined,
    image: input.image || undefined,
    facebookUrl: input.facebook_url || undefined,
    redditUrl: input.reddit_url || undefined,
    telegramUrl: input.telegram_url || undefined,
    slackUrl: input.slack_url || undefined,
    twitterHandle: input.twitter_handle || undefined,
    hasTradingIncentive: input.has_trading_incentive || false,
    centralized: input.centralized || false,
    isDex: DEFI_EXCHANGES.includes(input.name || ""),
    publicNotice: input.public_notice || undefined,
    alertNotice: input.alert_notice || undefined,
    trustScore: toNumber(input.trust_score),
    trustScoreRank: toNumber(input.trust_score_rank),
    tradeVolume24hBtc: toNumber(input.trade_volume_24h_btc),
    tradeVolume24hBtcNormalized: toNumber(
      input.trade_volume_24h_btc_normalized
    ),
    tickers: mapCoinTickers({ data: input.tickers || undefined }),
  };

  return mappedItems;
};
