import { getExchangeDetailQueryUrl } from "../../../data/coingecko-endpoints";
import { ExchangeListItem } from "../../../graphql-types";
import { ExchangeDetailsResult } from "../../../types/api-results/exchange-details";
import { FETCH_INTERVAL } from "../../constants/data-fetch";
import { mapExchangeDetails } from "../../utils/data-mappings/map-exchange-details";
import { useAxios } from "./use-axios";

interface UseExchangeDetailsInput {
  id: string;
  staticData?: ExchangeListItem;
}

export const useExchangeDetails = ({
  id,
  staticData,
}: UseExchangeDetailsInput) => {
  const url = getExchangeDetailQueryUrl(id);

  const { data, error, loading, timestamp } = useAxios<ExchangeDetailsResult>(
    url,
    {
      refetchInterval: FETCH_INTERVAL,
    }
  );

  return {
    data: mapExchangeDetails(data || staticData),
    error,
    loading,
    timestamp,
  };
};
