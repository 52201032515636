import { PageProps } from "gatsby";
import * as React from "react";
import { ExchangeListItem } from "../../graphql-types";
import Layout from "../components/layout/layout";
import { NavTab } from "../components/layout/navigation/navigation";
import Exchange from "../components/pages/exchange/exchange";
import SEO from "../components/seo";

type ExchangePageProps = PageProps<null, { exchange: ExchangeListItem }>;

const ExchangePage: React.FC<ExchangePageProps> = ({
  pageContext: { exchange },
}) => {
  const exchangeName = exchange.name || "Exchange";
  return (
    <Layout activeTab={NavTab.Exchanges}>
      <SEO
        title={`${exchangeName} market stats and cryptocurrencies`}
        description={`${exchangeName} exchange market info, pair list and most active coins. Find out what can you buy ${exchangeName}.`}
      />

      <Exchange id={exchange.id} staticData={exchange} />
    </Layout>
  );
};

export default ExchangePage;
