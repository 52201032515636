import * as React from "react";
import { ExchangeListItem } from "../../../../graphql-types";
import { useExchangeDetails } from "../../../hooks/query-hooks/use-exchange-details";
import BackAndSearch from "../../shared/back-and-search/back-and-search";
import Container from "../../ui-library/container/container";
import ExchangeOverview from "./exchange-overview/exchange-overview";

interface ExchangeProps {
  id: string;
  staticData?: ExchangeListItem;
}

const Exchange: React.FC<ExchangeProps> = ({ id, staticData }) => {
  const { data, loading } = useExchangeDetails({ id, staticData });

  return (
    <>
      <BackAndSearch />

      <Container fullWidthOnMobile={true}>
        <ExchangeOverview data={data} loading={loading} />
      </Container>
    </>
  );
};

export default Exchange;
