import { CSSObject } from "@emotion/react";
import * as React from "react";
import { Colors } from "../design-tokens/colors";

interface ChevronIconProps {
  color?: Colors;
  direction?: "up" | "down" | "left" | "right";
  size?: number;
}

const root: CSSObject = {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.2s ease",
};
const left: CSSObject = {
  transform: "rotate(180deg)",
};
const up: CSSObject = {
  transform: "rotate(-90deg)",
};
const down: CSSObject = {
  transform: "rotate(90deg)",
};

const styles = {
  root,
  left,
  up,
  down,
};

export const ChevronIcon: React.FC<ChevronIconProps> = ({
  color,
  direction,
  size,
}) => {
  const style = direction && direction !== "right" && styles[direction];

  const iconHeight = size || 20;
  const iconWidth = Math.round((iconHeight / 20) * 12);

  return (
    <span css={[styles.root, style]}>
      <svg
        width={iconWidth}
        height={size || 20}
        viewBox="0 0 12 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color || Colors.Black}
          d="M1.414 0L12 10.01 1.414 20 0 18.527l9.13-8.517L.062 1.514 1.414 0"
          fillRule="evenodd"
        />
      </svg>
    </span>
  );
};
