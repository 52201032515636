export const EXCHANGE_URLS = [
  {
    id: "binance",
    name: "Binance",
    affiliateUrl: "https://www.binance.com/en/register?ref=YT78A1DL",
  },
  {
    id: "huobi",
    name: "Huobi Global",
    affiliateUrl:
      "https://www.huobi.com/en-us/topic/invited/?invite_code=jaw73",
  },
  {
    id: "bitfinex",
    name: "Bitfinex",
    affiliateUrl: "https://www.bitfinex.com/?refcode=Oarzd8FtQ",
  },
  {
    id: "coinspot",
    name: "CoinSpot",
    affiliateUrl: "https://www.coinspot.com.au?affiliate=ZMMC95",
  },
  {
    id: "poloniex",
    name: "Poloniex",
    affiliateUrl: "https://poloniex.com/signup?c=9N6NLSFW",
  },
  {
    id: "kucoin",
    name: "KuCoin",
    affiliateUrl: "https://www.kucoin.com/ucenter/signup?rcode=E3Imi6",
  },
  {
    id: "bittrex",
    name: "Bittrex",
    affiliateUrl:
      "https://bittrex.com/Account/Register?referralCode=AF1-TPZ-TOI",
  },
  {
    id: "gate",
    name: "Gate.io",
    affiliateUrl: "https://www.gate.io/ref/719682",
  },
  {
    id: "hotbit",
    name: "Hotbit",
    affiliateUrl: "https://www.hotbit.io/register?ref=729796",
  },
  {
    id: "bitmax",
    name: "BitMax",
    affiliateUrl: "https://bitmax.io/register?inviteCode=POXNMVSI",
  },
  {
    id: "coinex",
    name: "CoinEx",
    affiliateUrl: "https://www.coinex.com/register?refer_code=xk96a",
  },
  {
    id: "bibox",
    name: "Bibox",
    affiliateUrl: "https://www.bibox.com/v2/login/register?invite_code=05KSHB",
  },
  {
    id: "bilaxy",
    name: "Bilaxy",
    affiliateUrl: "https://bilaxy.com/user/register?intro=6033",
  },
];

export const HASHED_EXCHANGE_URLS = EXCHANGE_URLS.reduce(
  (urls: Record<string, string>, exchange) => {
    if (!urls[exchange.name]) {
      urls[exchange.name] = exchange.affiliateUrl;
    }
    return urls;
  },
  {}
);
