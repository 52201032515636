import * as React from "react";
import { ExchangeDetailsMapped } from "../../../../../types/api-results/exchange-details";
import { SiteContext } from "../../../../site-context/site-context";
import { EventCategory } from "../../../../utils/analytics";
import CoinTickersTable from "../../../shared/coin-tickers-table/coin-tickers-table";
import CoinTickersTableSkeleton from "../../../shared/coin-tickers-table/coin-tickers-table-skeleton";
import PillButton from "../../../ui-library/buttons/pill-button";
import Heading from "../../../ui-library/text/heading";
import styles from "./exchange-coin-tickers.styles";

interface ExchangeCoinTickersProps {
  data?: ExchangeDetailsMapped;
  loading?: boolean;
}

const ExchangeCoinTickers: React.FC<ExchangeCoinTickersProps> = ({
  data,
  loading,
}) => {
  const { btcExchangeRate } = React.useContext(SiteContext);

  if (!data) {
    return null;
  }

  const { name, url, affiliateUrl, tradeVolume24hBtc } = data;

  const totalVolume = tradeVolume24hBtc
    ? tradeVolume24hBtc * (btcExchangeRate || 1)
    : undefined;

  return (
    <>
      <div css={styles.headerCointainer}>
        <Heading css={styles.heading}>Spot Markets</Heading>

        {name && (
          <PillButton
            to={affiliateUrl || url}
            name={
              affiliateUrl ? "Create Free Trading Account" : "Visit exchange"
            }
            color="blue"
            css={styles.visitExchangeButton}
            target="_blank"
            eventCategory={EventCategory.ExchangePage}
          >
            {affiliateUrl ? "Create Free Trading Account" : "Visit exchange"}
          </PillButton>
        )}
      </div>

      {loading && <CoinTickersTableSkeleton />}

      {totalVolume && data.tickers && (
        <CoinTickersTable
          data={data.tickers}
          totalVolume={totalVolume}
          tableFor="exchange"
          eventCategory={EventCategory.CoinPage}
        />
      )}
    </>
  );
};

export default ExchangeCoinTickers;
